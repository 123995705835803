<div class="panel panel-default">
	<div class="panel-body">
		<div class="filter-title text-left {{hMarketClass}}">
			<span id="currentNumberOfItems">{{totalNumberOfMatches}}</span> <span style="font-weight: normal">{{{numbItemsLabel}}} {{{JspPublicCodes_JSP_FOUND}}}</span>
		</div>
		{{#if showOpenOnly}}
			<div class="row">
				<div class="col-md-12">{{{JspPublicCodes_JSP_TIMED_EMPTY_FILTERS}}}</div>
			</div>
		{{else}}
			{{#if filtering}}
				<a class="x-remove btn btn-link pull-right" id="clear-filters" data-filterid="all" href="{{baseUrl}}">{{{JspPublicCodes_JSP_CLEAR_ALL_FILTERS}}}</a>
			{{/if}}
			<ul id="filters" class="filter-list list-unstyled wrapperFiltersPaddingList margin-bottom--none">
				{{#if countryFiltering}}
					<li>
						<h4 class="category-filter-title">{{{countryCurrentViewName}}}:</h4>
						<span id="countryName"><strong>{{{countryFilterName}}}</strong></span>
						<a id="removeCountry" class="x-remove fa fa-times" data-filterid="country" href="{{baseUrl}}"></a>
					</li>
				{{/if}}

				{{#if categoryTreeFiltering}}
					<li>
						<h4 class="category-filter-title">{{{JspPublicCodes_JSP_CATEGORY}}}:</h4>
						<strong>{{{categoryTreeName}}}</strong>
						<a class="x-remove fa fa-times" data-filterid="tree" href="#"></a>
					</li>
				{{/if}}

				{{#if categoryParentFiltering}}
					<li>
						<h4 class="category-filter-title">{{{JspPublicCodes_JSP_CATEGORY}}}:</h4>
						<strong>{{{categoryTreeName}}}</strong> <a class="x-remove fa fa-times" data-filterid="category" href="#"></a>
						<ul class="fa-ul">
							<li class="cat-filter-parent">
								<i class="fa fa-level-down"></i>{{{categoryParentName}}} <a class="x-remove fa fa-times" data-filterid="category" href="#"></a>
							</li>
						</ul>
					</li>
				{{/if}}

				{{#if categoryChildFiltering}}
					<li>
						<h4 class="category-filter-title">{{{JspPublicCodes_JSP_CATEGORY}}}:</h4>
						<strong>{{{categoryTreeName}}}</strong> <a class="x-remove fa fa-times" data-filterid="tree" href="#"></a>
						<ul class="fa-ul">
							<li class="cat-filter-parent">
								<i class="fa fa-level-down"></i>
								<span>{{{categoryParentName}}}</span> <a class="x-remove fa fa-times" data-filterid="category" href="#"></a>
								<ul class="fa-ul">
									<li class="cat-filter-child">
										<i class="fa fa-level-down"></i>
										<span>{{{categoryChildName}}}</span> <a class="x-remove fa fa-times" data-filterid="subCategory" href="#"></a>
									</li>
								</ul>
							</li>
						</ul>
					</li>
				{{/if}}

				{{#if queryFiltering}}
					<li>
						<h4 class="category-filter-title">{{{queryCurrentViewName}}}</h4>
						<strong>{{{queryFilterName}}}</strong>
							<a class="x-remove fa fa-times" data-filterid="query" href="#"></a>
					</li>
				{{/if}}

				{{#if auctionTypeFiltering}}
					<li>
						<h4 class="category-filter-title">{{{auctionTypeCurrentViewName}}}</h4>
						<strong>{{{auctionTypeFilterName}}}</strong>
						<a class="x-remove fa fa-times" data-filterid="auctionType" href="#"></a>
					</li>
				{{/if}}

				{{#if itemTypeFiltering}}
					<li>
						<h4 class="category-filter-title">{{{itemTypeCurrentViewName}}}</h4>
						<strong>{{{itemTypeFilterName}}}</strong>
						<a id="removeItemType" class="x-remove fa fa-times" data-filterid="itemType" href="#"></a>
					</li>
				{{/if}}

			</ul>
		{{/if}}
	</div>
</div>
