{{#if displayFilter}}
<div class="panel-group" id="itemTypeAccordion" role="tablist" aria-multiselectable="true">
	<div class="panel panel-default">
		<div class="panel-heading" role="tab" id="itemTypeFilters">
			<h4 class="panel-title">
				<a role="button" data-toggle="collapse" data-parent="#itemTypeAccordion" href="#itemTypePanel" aria-expanded="true" aria-controls="auctionTypePanel">
					{{{filterHeaderName}}}<i class="fa fa-bars pull-right"></i>
				</a>
			</h4>
		</div>
		<div id="itemTypePanel" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="itemTypeFilters">
			<div class="panel-body">
				<ul id="item-type-filters" class="filter-list">
					{{#filterItems}}
					<li>
						<a class="x-type filter-item" href="#" data-itemid="{{item.id}}">
							<span id="{{../queryParameterName}}-{{item.id}}">{{{item.name}}}
								<span id="count-{{../queryParameterName}}-{{item.id}}" class="count">({{count}})</span>
							</span>
						</a>
					</li>
					{{/filterItems}}
				</ul>
			</div>
		</div>
	</div>
</div>
{{/if}}
