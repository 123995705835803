{{#if showSaleInfoInPage}}
	<div class="tab-content">
		{{#if isAuctionLand}}
				<div role="tabpanel" class="tab-pane {{{saleInfoActive}}} sale-info-tab-pane" id="saleinfo">
				<div class="row">
					<div id="saleInfoRegion" class="col-md-12"></div>
				</div>
			</div>
		{{/if}}
		<div role="tabpanel" class="tab-pane {{{filtersActive}}} filters-tab-pane" id="filters">
			<div class="row">
				<div id="currentFilterRegion" class="filter-region col-md-12"></div>
			</div>
			<div class="row">
				<div id="categoriesRegion" class="filter-region col-md-12"></div>
			</div>
			<div class="row">
				<div id="auctionTypeRegion" class="filter-region col-md-12"></div>
			</div>
			<div class="row">
				<div id="itemTypeRegion" class="filter-region col-md-12"></div>
			</div>
			<div class="row">
				<div id="continentsRegion" class="filter-region col-md-12"></div>
			</div>
			<div class="row">
				<div id="infoRegion" class="filter-region col-md-12"></div>
			</div>
		</div>
	</div>
{{else}}
	<div class="row">
		<div id="currentFilterRegion" class="col-md-12"></div>
	</div>
	<div class="row">
		<div id="categoriesRegion" class="col-md-12"></div>
	</div>
	<div class="row">
		<div id="auctionTypeRegion" class="col-md-12"></div>
	</div>
	<div class="row">
		<div id="itemTypeRegion" class="col-md-12"></div>
	</div>
	<div class="row">
		<div id="continentsRegion" class="col-md-12"></div>
	</div>
	<div class="row">
		<div id="infoRegion" class="col-md-12"></div>
	</div>
{{/if}}
