
<form id="address-change" class="form-horizontal">
	<div id="address-title" class="form-group">
		<div class="col-sm-4 text-right">
			<strong>&nbsp;</strong>
		</div>
		<div class="col-sm-6">
			<h4>{{{viewTitle}}}</h4>
		</div>
	</div>
	<div id="address-line1" class="form-group {{hasError1}}">
		<div class="col-sm-4 text-right">
			<strong>Line 1 <sup>*</sup></strong>
		</div>
		<div class="col-sm-6 input-group">
			<input type="text" class="form-control" id="line1" placeholder="Address Line 1" value="{{{address.addressLine1}}}" maxlength="250"/>
		</div>
	</div>
	<div id="address-line2" class="form-group">
		<div class="col-sm-4 text-right">
			<strong>Line 2</strong>
		</div>
		<div class="col-sm-6 input-group">
			<input type="text" class="form-control" id="line2" placeholder="Address Line 2" value="{{{address.addressLine2}}}" maxlength="250"/>
		</div>
	</div>
	<div id="address-line3" class="form-group">
		<div class="col-sm-4 text-right">
			<strong>Line 3</strong>
		</div>
		<div class="col-sm-6 input-group">
			<input type="text" class="form-control" id="line3" placeholder="Address Line 3" value="{{{address.addressLine3}}}" maxlength="250"/>
		</div>
	</div>
	<div id="address-line4" class="form-group">
		<div class="col-sm-4 text-right">
			<strong>Line 4</strong>
		</div>
		<div class="col-sm-6 input-group">
			<input type="text" class="form-control" id="line4" placeholder="Address Line 4" value="{{{address.addressLine4}}}" maxlength="250"/>
		</div>
	</div>
	<div id="address-city" class="form-group">
		<div class="col-sm-4 text-right">
			<strong>City</strong>
		</div>
		<div class="col-sm-6 input-group">
			<input type="text" class="form-control" id="city" placeholder="City" value="{{{address.city}}}" maxlength="250"/>
		</div>
	</div>
	<div id="address-county" class="form-group {{hasErrorCounty}}">
		<div class="col-sm-4 text-right">
			<strong>County <sup>*</sup></strong>
		</div>
		<div class="col-sm-6 input-group">
			<input type="text" class="form-control" id="county" placeholder="County" value="{{{address.county}}}" maxlength="250"/>
		</div>
	</div>
	<div id="address-postcode" class="form-group {{hasErrorPostcode}}">
		<div class="col-sm-4 text-right">
			<strong>Post code</strong> <sup>*</sup>
		</div>
		<div class="col-sm-6 input-group">
			<input type="text" class="form-control" id="postcode" placeholder="Postcode" value="{{{address.postcode}}}" maxlength="50"/>
		</div>
	</div>
	<div id="address-country" class="form-group">
		<div class="col-sm-4 text-right">
			<strong>Country</strong>
		</div>
		<div class="col-sm-6 input-group">
			<select id="country" class="x-country form-control">
				{{#countries}}
				<option value="{{id}}">{{name}}</option>
				{{/countries}}
			</select>
		</div>
	</div>
	<div class="form-group {{hasErrorCountry}}">
		<div class="col-sm-4 text-right">
			<strong></strong>
		</div>
		<div class="col-sm-6 input-group">
			<button type="submit" class="x-save-address btn btn-primary">{{{JSTextCodes_SAVE}}}</button>
			&nbsp;
			{{#if editing}}<button type="submit" class="x-back btn btn-primary">{{{JSTextCodes_BACK}}}</button>{{/if}}
		</div>
	</div>
</form>
