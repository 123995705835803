<nav class="clearfix">
  <ul class="pagination pagination-sm pull-right">
    <!-- goto first page -->
    <li>
		<a class="x-goto{{controller}}" id="pagination0{{{paginationPosition}}}" href="#" data-goto="0"><span aria-hidden="true">&larr;</span></a>
    </li>
    <!-- previous arrow -->
	{{#if previous}}
    	<li id="pagination-{{{paginationPosition}}}--previous">
			<a class="x-previous{{controller}}" href="#" aria-label="Previous"><span aria-hidden="true">&laquo;</span></a>
	    </li>
    {{else}}
        <li id="pagination-{{{paginationPosition}}}--previous">
            <span class="disabled" aria-hidden="true">&laquo;</span>
        </li>
	{{/if}}
    <!-- page numbers -->
    {{#pages}}
    	{{#if link}}
    		<li id="pagination-{{{paginationPosition}}}--{{label}}"><a  class="x-goto{{../controller}}" id="{{id}}" href="#" data-goto="{{gotoIndex}}">{{label}}</a></li>
    	{{else}}
			<li id="pagination-{{{paginationPosition}}}--{{label}}" class="active"><span>{{{label}}}</span></li>
		{{/if}}
	{{/pages}}
    <!-- next arrow -->
    {{#if next}}
        <li id="pagination-{{{paginationPosition}}}--next">
			<a class="x-next{{controller}}" href="#" aria-label="Next"><span aria-hidden="true">&raquo;</span></a>
        </li>
    {{else}}
        <li id="pagination-{{{paginationPosition}}}--next">
            <span class="disabled" aria-hidden="true">&raquo;</span>
        </li>
    {{/if}}
    <!-- goto last page -->
	<li id="pagination-{{{paginationPosition}}}--last">
		<a class="x-goto{{controller}}" id="pagination{{lastPage}}{{{paginationPosition}}}" href="#" data-goto="{{lastPage}}"><span aria-hidden="true">&rarr;</span></a>
	</li>
    <!-- pages count text -->
	<li id="pagination-{{{paginationPosition}}}--total"><span class="total-pages">{{{pageOfMsg}}}</span></li>
  </ul>
</nav>
