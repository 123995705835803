<div class="panel-group" id="marketplaceInfoAccordian" role="tablist" aria-multiselectable="true">
	<div class="panel panel-default">
		<div class="panel-heading" role="tab" id="marketplaceInfoHeading">
			<h4 class="panel-title">
				<a role="button" data-toggle="collapse" data-parent="#marketplaceInfoAccordian" href="#marketplaceInfoPanel" aria-expanded="true" aria-controls="marketplaceInfoPanel">
					{{{JspPublicCodes_JSP_INFORMATION}}}<i class="fa fa-bars pull-right"></i>
				</a>
			</h4>
		</div>
		<div id="marketplaceInfoPanel" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="marketplaceInfoHeading">
			<div class="panel-body">
                {{{mpText}}}
            </div>
        </div>
    </div>
</div>
