<div class="row {{hclass}}">
	<div class="col-md-12">
		<h1 class="auction-title">{{{message}}}</h1>
		{{#if available}}
			<h2 class="auction-title-available h4">({{{available}}})</h2>
		{{/if}}
		{{#if description}}
			<p class="auction-description"><i>{{{description}}}</i></p>
		{{/if}}
		{{#if webcast}}
			{{#if endDate}}
				<h5 class="auction-end-date"><strong>Starts: </strong>{{endDate}} from {{endTime}}</h5>
			{{/if}}
		{{else}}
			{{#if startDate}}
				<h5 class="auction-start-date"><strong>Starts: </strong>{{startDate}} from {{startTime}}</h5>
			{{/if}}
			{{#if endDate}}
				<h5 class="auction-end-date"><strong>Ends: </strong>{{endDate}} from {{endTime}}</h5>
			{{/if}}
		{{/if}}
	</div>
</div>
