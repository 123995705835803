{{#if auctionSummaries}}
	<div class="panel panel-default">
		<div class="panel-heading">
			<h4 class="panel-title">{{{JspPublicCodes_JSP_MY_BIDS_AUCTION}}}</h4>
		</div>
			<ul class="list-group">
				{{#auctionSummaries}}
					<li class="list-group-item {{#if active}}active{{/if}}">
						<a class="x-search-auction" data-auction="{{{id}}}" id="myBidsAuctionLink{{{id}}}" href="#">{{{title}}}</a>
						<a class="x-view-auction" href="{{../contextPath}}/auctionDetails/{{{id}}}"><i class="fa fa-external-link"></i></a>
						{{#if active}}<a class="x-remove-auction" href="#"><i class="fa fa-times-circle-o"></i></a>{{/if}}
					</li>
				{{/auctionSummaries}}
			</ul>
	</div>	
{{/if}}
