<div class="row text-center currentBidInner {{borderedClass}}">
<!-- timed-gal-bids -->
<div class="w2-highbid-inplay">
	{{#if showWinningBidAmount}}
		<span id="highestBidMessage{{id}}">{{{bidMessage}}}: <strong>
		<span id="highestBid{{id}}">{{{priceToDisplay}}}</span></strong></span>
		<span id="highBidAmountCalculation{{id}}">{{{highBidAmountCalculation}}}</span>
	{{else if showDisplayPrice}}
		<span id="displayPriceMessage{{id}}">{{{displayPriceMessage}}}: <strong>
		<span id="displayPrice{{id}}">{{{displayPriceWithCurrency}}}</span></strong></span>
    {{else}}
		<span><strong>&nbsp;</strong></span>
	{{/if}}
</div>
{{#if showEither}}
	{{#if showBidIncrements}}
	<div class="{{showBothClass}} comment text-center">
		<small id="bidIncrementMessage{{id}}" class="comment text-center">{{{JspPublicCodes_JSP_BIDDING_BID_INCREMENT}}}: <span id="increment{{id}}"
							class="comment">{{{bidIncrementWithCurrency}}}</span></small>
	</div>
	{{/if}}
	{{#if showNumberOfBids}}
	<div class="{{showBothClass}}">
		<small id="numberOfBidsMessage{{id}}" class="comment text-center">{{{JspPublicCodes_JSP_MY_SALES_NUM_BIDS}}}: <span id="numberOfBids{{id}}">{{{numberOfBids}}}</span></small>
	</div>
	{{/if}}
{{else}}
	<div><small class="comment text-center">&nbsp;</small></div>
{{/if}}

<div class="col-md-12">
	{{#if showNumberOfUnits}}
		<small id="numberOfUnitsMessage{{id}}" class="comment text-center">{{{numberOfUnits}}}</small>&nbsp;
	{{else}}
			&nbsp;
	{{/if}}
</div>

<div id="bidTypeMessage{{id}}" class="col-md-12">
	{{#if showBidTypeMessage}}
		<small class="comment">{{{bidTypeMessage}}}</small>
	{{/if}}
</div>

{{#if showBulkBuyData}}
	<div class="col-md-6 comment text-center">
		<small id="numberOfUnits{{id}}" class="comment text-center">{{{numberOfUnits}}}</small>
	</div>
	<div class="col-md-6">
		<small id="numberOfUnitsRemaining{{id}}" class="comment text-center">{{{numberOfUnitsRemaining}}}</small>
	</div>
{{/if}}


</div>
