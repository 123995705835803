<div id="searchInputView">
    <form class="form-horizontal" id="searchForItems" method="post" action="search" role="form">
        <div class="form-group">
            <div class="col-xs-12 col-sm-5 margin-top">
                <label class="sr-only" for="searchFilter">{{{JspPublicCodes_JSP_HEADER_SEARCH_ITEMS}}}</label>
                <input class="x-query-input form-control" id="searchFilter" placeholder="{{{JspPublicCodes_JSP_HEADER_SEARCH_ITEMS}}}" type="search" maxlength="256" name="query" value="{{query}}"/>
            </div>
            {{#if multiTreeAuctioneer}}
                <div class="col-xs-12 col-sm-4 margin-top">
                    <label for="categoryTree" class="sr-only">Categories</label>
                    <select id="categoryTree" class="x-tree-input form-control">
                        <option value="">{{{JspPublicCodes_JSP_LABEL_ALL_CATEGORIES}}}</option>
                        {{#each categoryTrees}}
                            <option value="{{{value}}}">{{{label}}}</option>
                        {{/each}}
                    </select>
                </div>
            {{/if}}
            <div class="col-xs-12 col-sm-3 margin-top">
                <button class="btn btn-primary btn-block search-submit" type="submit" id="search-submit">
                    <span class="search-submit-text">{{{JspPublicCodes_JSP_HEADER_SEARCH_ITEMS}}}</span> <i class="fa fa-search"></i>
                </button>
            </div>
        </div>
    </form>
</div>
