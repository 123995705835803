<div>
    <div id="itemActionsMessage{{id}}" class="w2-messages-box">
 <!-- timed-gal-bids -->
        <div class="row">
            <div class="message-box-wrapper col-md-12 text-center {{classes}}">
            {{#if userMessage}}
                <h5 id="biddingBarStatus_{{id}}_{{sellingStatus}}">{{{userStatusMessage}}}</h5>
            {{else}}
                <h5>{{{userStatusMessage}}}</h5>
            {{/if}}
            </div>
        </div>
    </div>
</div>
<div id="reserveIndicator" class="col-md-12">
{{#if showReserveIndicator}}
	<div class="progress style-1" >
		<div id="progress-bar" class="progress-bar progress-bar-success progress-bar-striped active"  role="progressbar" data-animate-width="{{percentOfReserve}}%" style="width: {{percentOfReserve}}%;"></div>
	</div>
{{else}}
	<p>&nbsp;</p>
{{/if}}
</div>
