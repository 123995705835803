
<div class="modal-dialog" role="document">
	<div class="modal-content">
		<div class="modal-header">
			<button type="button" class="close" data-dismiss="modal" aria-label="Close">
				<span aria-hidden="true">&times;</span><span class="sr-only">{{{JSTextCodes_CLOSE}}}</span>
			</button>
			<h4 class="modal-title" id="myModalLabel">{{{JspPublicCodes_PURCHASE_DIALOG_TITLE}}}</h4>
		</div>
		<div class="modal-body">
			<div class="row">
				<div class="col-md-12">
					<h3>{{{JspPublicCodes_PURCHASE_DIALOG_MAKE_OFFER}}}</h3>
				</div>
			</div>
			{{#if hasOffers}}
			<div class="row text-center">
				<div class="col-md-12">
					<h5>{{{offerMessage}}}</h5>
				</div>
			</div>
			{{/if}}
			<div class="row">
           	    <div class="col-md-12">
			        <h4>{{{numberOrRef}}}: {{{title}}}</h4>
			    </div>
			</div>
			<div class="row text-center">
				<div class="col-md-12">
					<h5>Units Available: {{{numberOfUnitsRemaining}}}</h5>
				</div>
			</div>
			{{#if hasResult}}
			<div id="offerResultMessage" class="{{resultClass}} text-center"> 
				<label class="col-sm-12">{{{resultMessage}}}</label>
			</div>
			{{/if}}
			{{#if allowedMoreOffers}}
				<div id="field1" class="form-group {{error1Class}}">
					<label class="col-sm-4 control-label" for="offerAmount">{{{label1}}}</label>
					<div class="col-sm-6 input-group">
						<div class="input-group-addon">{{{currencySymbol}}}</div>
						<input type="text" class="x-add-commas form-control" id="offerAmount" placeholder="Amount"  value="{{offerAmount}}" maxlength="16"/>
						<div class="input-group-addon">.00</div>
					</div>
				</div>
				<div class="form-group has-warning">
					<label class="col-sm-4 control-label" for="">&nbsp;</label>
					<div class="col-sm-6 input-group">
						<p class="help-block">plus {{{JspPublicCodes_PURCHASE_DIALOG_BUYERS_PREMIUM_AT}}} %{{{buyersPremium}}}</p>
					</div>
				</div> 
				<div id="field2" class="form-group {{error2Class}}">
					<label class="col-sm-4 control-label" for="numberRequired">{{{label2}}}</label>
					<div class="col-sm-6 input-group">
						<input type="text" class="form-control x-numeric-only" id="numberRequired" placeholder="number required - {{{numberOfUnitsRemaining}}} left" value="{{numberRequired}}" maxlength="16">
					</div>
				</div>
				<div class="form-group">
					<label class="col-sm-4 control-label" for="deliver">Collection</label>
					<div class="col-sm-6 input-group" for="collection-collect">
						<input class="x-collection" type="radio" name="collection" id="collection-collect" value="collect"{{collectionCollect}}> I will collect
					</div>
				</div>
				<div class="form-group">
					<label class="col-sm-4 control-label" for="offerAmount">&nbsp;</label>
					<div class="col-sm-6 input-group" for="collection-deliver">
						<input class="x-collection" type="radio" name="collection" id="collection-deliver" value="deliver"{{collectionDeliver}}> My offer includes
						delivery to my address.
					</div>
				</div>
				{{#if showShipping}}
				<div id="show-shipping">
					<div class="form-group">
						<label class="col-sm-4 control-label" for="shipTo">Ship To</label>
						<div class="col-sm-6 input-group">
							<select id="shipto" class="x-shipping-address form-control">
								<option value="0"{{useAddress1}}>My Main Address</option>
								<option value="1"{{useAddress2}}>My Shipping Address</option>
							</select>
						</div>
					</div>
				</div>
				<div id="addressRegion"></div>
				{{/if}}
			{{/if}} 
			<div class="modal-footer">
				<div class="row">
					<div class="col-md-12">
						{{#if allowedMoreOffers}}
						<button type="submit" class="x-multi-offer btn btn-primary">{{{JSTextCodes_PROCEED}}}</button>
						{{/if}}
						<button type="submit" class="x-cancel btn btn-primary">{{{JSTextCodes_CLOSE}}}</button>
					</div>
				</div>
				<div class="row text-left" style="margin: 20px 0 0 0;">
					<div class="col-md-12">
						<div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
							<div class="panel panel-default">
								<div class="panel-heading" role="tab" id="headingOne">
									<h4 class="panel-title">
										<a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="false"
											aria-controls="collapseOne">{{{helpTitle}}}</a>
									</h4>
								</div>
								<div id="collapseOne" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
									<div class="panel-body">{{{helpContent}}}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
