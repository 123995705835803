{{#if showSaleInfoInPage}}
	<div class="panel-group" id="saleInfoAccordian" role="tablist" aria-multiselectable="true">
		<div class="panel panel-default">
			<div class="panel-heading" role="tab" id="saleInfoHeading">
				<h4 class="panel-title">
					<a role="button" data-toggle="collapse" data-parent="#saleInfoAccordian" href="#saleInfoContent" aria-expanded="true" aria-controls="saleInfoContent">
		  				<i class="fa fa-info-circle"></i>&nbsp;{{{JspPublicCodes_JSP_AUCTION_INFORMATION}}}
					</a>
	  			</h4>
			</div>
			<div id="saleInfoContent" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="saleInfoHeading">
				<div class="panel-body">
					<div>
						{{#if hasAddress}}
							<strong>{{{JspPublicCodes_JSP_AUCTION_INFORMATION_SITE_ADDRESS}}}:</strong>
							<p id="saleInfoAddress">{{{address}}}</p>
						{{/if}}
						{{#if webcast}}
							{{#if inLiveBidding}}
								<p>{{{JspPublicCodes_JSP_LIVE_AUCTION_STARTED}}}</p>
							{{/if}}
							{{#if inPreBidding}}
								<p><strong>{{{JspPublicCodes_JSP_LIVE_AUCTION_STARTS}}}:</strong> {{{auctionDatePart}}} {{{auctionTimePart}}}</p>
							{{/if}}
						{{else}}
							{{#if auctionStartDatePart}}
								<p><strong>{{{JspPublicCodes_JSP_AUCTION_INFORMATION_STARTING_DATE}}}:</strong> {{{auctionStartDatePart}}} {{{JspPublicCodes_JSP_AUCTION_INFORMATION_FROM}}} {{{auctionStartTimePart}}}</p>
							{{/if}}
							<p><strong>{{{JspPublicCodes_JSP_AUCTION_INFORMATION_CLOSING_DATE}}}:</strong> {{{auctionDatePart}}} {{{JspPublicCodes_JSP_AUCTION_INFORMATION_FROM}}} {{{auctionTimePart}}}</p>
						{{/if}}
					</div>
					<div>
						<p><strong>{{{JspPublicCodes_JSP_AUCTION_INFORMATION_CURRENCY}}}:</strong> {{{currencyCode}}}</p>
					</div>

					{{#if notCatalogue}}
						<p><strong>{{{JspPublicCodes_JSP_AUCTION_INFORMATION_BUYERS_PREMIUM}}}:</strong>
							<span id="buyersPremium">{{#if buyersPremiumRequired}} {{{buyersPremium}}}&#37; {{else}} 0&#37; {{/if}}{{{JspPublicCodes_JSP_AUCTION_INFORMATION_OR_AS_SPECIFIED}}}</span>
						</p>
						<p>
							{{#if salesTaxRequired}}
								<span id="salesTax">
	  							<strong>{{{JspPublicCodes_JSP_AUCTION_INFORMATION_SALES_TAXES}}}:</strong>
	  							<br/>
	  							<span>{{{JspPublicCodes_JSP_AUCTION_INFORMATION_HAMMER_TAX_RATE}}}: {{{hammerTaxRate}}}&#37; {{{JspPublicCodes_JSP_AUCTION_INFORMATION_OR_AS_SPECIFIED}}}</span>
								<br/>
								<span>{{{JspPublicCodes_JSP_AUCTION_INFORMATION_BP_TAX_RATE}}}: {{{bpTaxRate}}}&#37; {{{JspPublicCodes_JSP_AUCTION_INFORMATION_OR_AS_SPECIFIED}}}</span>
								<br/>
								<span>{{{JspPublicCodes_JSP_AUCTION_INFORMATION_CHARGES_TAX_RATE}}}: {{{chargesTaxRate}}}&#37; {{{JspPublicCodes_JSP_AUCTION_INFORMATION_OR_AS_SPECIFIED}}}</span>
								<br/>
								</span>
							{{else}}
								<span id="noSalesTax">
	  								{{{JspPublicCodes_JSP_AUCTION_INFORMATION_SALES_TAX_NOT_REQUIRED_MESSAGE}}}
	  							</span>
							{{/if}}
						</p>
					{{/if}}
					<p><strong>{{{JspPublicCodes_JSP_AUCTION_INFORMATION_VIEWING_DAYS}}}:</strong></p>
					<p>
						<span id="viewingDaysInfo">
  							{{#if hasViewingDates}}
	  							<span>{{{viewingStartsDatePart}}} - {{{viewingClosesDatePart}}}</span>
								<br />
								<span>{{JspPublicCodes_JSP_AUCTION_INFORMATION_FROM}}
	  							{{{viewingStartsTimePart}}} {{JspPublicCodes_JSP_AUCTION_INFORMATION_TO}} {{{viewingClosesTimePart}}} </span>
								<br />
							{{/if}}
							{{#if requireAppointmentForViewing}}
								<span>{{{JspPublicCodes_JSP_AUCTION_INFORMATION_BY_APPOINTMENT}}}</span>
								<br />
							{{/if}}
							{{#if viewingComment}}
								<span>{{{viewingComment}}}</span>
							{{/if}}
						</span>
					</p>
					{{#if hasClearanceDatesOrComment}}
						<p>
							<strong>{{{JspPublicCodes_JSP_AUCTION_INFORMATION_CLEARANCE_DAYS}}}:</strong>
						</p>
						<p>
							<span id="clearanceDaysInfo">
						  		{{#if hasClearanceDates}}
						  			<span>{{{clearanceStartsDatePart}}} - {{{clearanceClosesDatePart}}}</span>
									<br/>
									<span>{{{JspPublicCodes_JSP_AUCTION_INFORMATION_FROM}}} {{{clearanceStartsTimePart}}} {{{JspPublicCodes_JSP_AUCTION_INFORMATION_TO}}} {{{clearanceClosesTimePart}}}</span>
									<br />
									{{/if}}
								{{#if clearanceComment}}
									<span>{{{clearanceComment}}}</span>
								{{/if}}
							</span>
						</p>
					{{/if}}
					{{#if validLatLong}}
						{{#if mapsApiKey}}
							<iframe
								id="mapSaleInfo"
								width="100%"
								height="450"
								frameborder="0"
								style="border:0"
								src="https://www.google.com/maps/embed/v1/place?key={{mapsApiKey}}&q={{locationLatitude}},{{locationLongitude}}"
								allowfullscreen
							/>
						{{/if}}
					{{/if}}
				</div>
			</div>
		</div>

		{{#if notCatalogue}}
			{{#if contentPages.hasAuctionCollection}}
				<div class="panel panel-default">
					<div class="panel-heading" role="tab" id="collectionInfoHeading">
						<h4 class="panel-title">
							<a role="button" class="collapsed" data-toggle="collapse" data-parent="#saleInfoAccordion" href="#collectionInfo" aria-expanded="false"
								aria-controls="collectionInfo"><i class="fa fa-truck"></i>&nbsp;{{{JspPublicCodes_JSP_AUCTION_INFORMATION_COLLECTION}}}</a>
						</h4>
					</div>
					<div id="collectionInfo" class="panel-collapse collapse" role="tabpanel" aria-labelledby="collectionInfoHeading">
						<div class="panel-body">{{{contentPages.auctionCollection}}}</div>
					</div>
				</div>
				{{/if}}
			{{#if contentPages.hasAuctionTerms}}
				<div class="panel panel-default">
					<div class="panel-heading" role="tab" id="termsConditionsHeading">
						<h4 class="panel-title">
							<a role="button" class="collapsed" data-toggle="collapse" data-parent="#saleInfoAccordion" href="#termsConditions" aria-expanded="false"
								aria-controls="termsConditions"><span class="fa fa-files-o"></span>&nbsp;{{{JspPublicCodes_JSP_AUCTION_INFORMATION_TS_AND_CS}}}</a>
						</h4>
					</div>
					<div id="termsConditions" class="panel-collapse collapse" role="tabpanel" aria-labelledby="termsConditionsHeading">
						<div class="panel-body">{{{contentPages.auctionTerms}}}</div>
					</div>
				</div>
			{{/if}}
			{{#if contentPages.hasAuctionPayment}}
				<div class="panel panel-default">
					<div class="panel-heading" role="tab" id="paymentInfoHeading">
						<h4 class="panel-title">
							<a role="button" class="collapsed" data-toggle="collapse" data-parent="#saleInfoAccordion" href="#paymentInfo" aria-expanded="true"
								aria-controls="paymentInfo"><span class="fa fa-credit-card"></span>&nbsp;{{{JspPublicCodes_JSP_AUCTION_INFORMATION_PAYMENT}}}</a>
						</h4>
					</div>
					<div id="paymentInfo" class="panel-collapse collapse" role="tabpanel" aria-labelledby="paymentInfoHeading">
						<div class="panel-body">{{{contentPages.auctionPayment}}}</div>
					</div>
				</div>
			{{/if}}
			{{#if contentPages.hasAuctionGoods}}
				<div class="panel panel-default">
					<div class="panel-heading" role="tab" id="collectionDetailsHeading">
						<h4 class="panel-title">
							<a role="button" class="collapsed" data-toggle="collapse" data-parent="#saleInfoAccordion" href="#collectionDetails" aria-expanded="false"
								aria-controls="collectionDetails"><span class="fa fa-truck"></span>&nbsp;{{{JspPublicCodes_JSP_AUCTION_INFORMATION_REMOVAL_OF_GOODS}}}</a>
						</h4>
					</div>
					<div id="collectionDetails" class="panel-collapse collapse" role="tabpanel" aria-labelledby="collectionDetailsHeading">
						<div class="panel-body">{{{contentPages.auctionGoods}}}</div>
					</div>
				</div>
			{{/if}}
		{{/if}}
		{{#if contentPages.auctionContacts}}
			<div class="panel panel-default">
				<div class="panel-heading" role="tab" id="saleContactsHeading">
					<h4 class="panel-title">
						<a role="button" class="collapsed" data-toggle="collapse" data-parent="#saleInfoAccordion" href="#saleContacts" aria-expanded="false"
								aria-controls="saleContacts"><span class="fa fa-phone"></span>&nbsp;{{{JspPublicCodes_JSP_AUCTION_INFORMATION_CONTACTS}}}</a>
					</h4>
				</div>
				<div id="saleContacts" class="panel-collapse collapse" role="tabpanel" aria-labelledby="saleContactsHeading">
					<div class="panel-body">{{{contentPages.auctionContacts}}}</div>
				</div>
			</div>
		{{/if}}
		{{#if documents}}
			<div class="panel panel-default">
				<div class="panel-heading" role="tab" id="documentsHeading">
					<h4 class="panel-title">
						<a role="button" class="collapsed" data-toggle="collapse" data-parent="#saleInfoAccordion" href="#documents" aria-expanded="false"
								aria-controls="documents"><span class="fa fa-file"></span>&nbsp;{{{JspPublicCodes_JSP_AUCTION_INFORMATION_ADDITIONAL_DOCS}}}</a>
					</h4>
				</div>
				<div id="documents" class="panel-collapse collapse" role="tabpanel" aria-labelledby="documentsHeading">
					<div class="panel-body">
						<ul class="documents">
							{{#documents}}
								<li><a href="{{attachmentUrl}}" target="_blank" title="{{{label}}}">{{{label}}}</a> &nbsp;<span class="comment">[{{size}}kB]</span></li>
							{{/documents}}
						</ul>
					</div>
				</div>
			</div>
		{{/if}}
	</div>
{{/if}}
{{#if modal}}
	<div class="modal-dialog modal-lg">
		<div class="modal-content">
			<div class="modal-header">
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span><span class="sr-only">{{{JSTextCodes_CLOSE}}}</span>
				</button>
				<h4 class="modal-title" id="myModalLabel">{{{JspPublicCodes_JSP_AUCTION_INFORMATION}}}</h4>
			</div>
			<div class="modal-body">
				<div class="row" style="margin-bottom: 40px;">
					{{#if auctionLogoSrc}}
					<div class="col-md-4">
						<a href="#"><img class="media-object img-responsive" alt="auction logog" src="{{{auctionLogoSrc}}}" /></a>
					</div>
					<div class="col-md-8">
						<h4 class="media-heading">Description</h4>
						<p>{{{description}}}</p>
					</div>
					{{else}}
					<div class="col-md-12">
						<h4 class="media-heading">Description</h4>
						<p>{{{description}}}</p>
					</div>
					{{/if}}
				</div>
				<div class="row">
					<div class="col-md-6">
						<div class="well">
								<div>
									{{#if webcast}}
										{{#if inLiveBidding}}
											{{{JspPublicCodes_JSP_LIVE_AUCTION_STARTED}}}
										{{/if}}
										{{#if inPreBidding}}
											<strong>{{{JspPublicCodes_JSP_LIVE_AUCTION_STARTS}}}:</strong>
											{{{auctionDatePart}}} {{{auctionTimePart}}}
										{{/if}}
									{{else}}
										{{#if auctionStartDatePart}}
											<p><strong>{{{JspPublicCodes_JSP_AUCTION_INFORMATION_STARTING_DATE}}}:</strong> {{{auctionStartDatePart}}} {{{JspPublicCodes_JSP_AUCTION_INFORMATION_FROM}}} {{{auctionStartTimePart}}}</p>
										{{/if}}
										<strong>{{{JspPublicCodes_JSP_AUCTION_INFORMATION_CLOSING_DATE}}}:</strong>
										{{{auctionDatePart}}} {{{JspPublicCodes_JSP_AUCTION_INFORMATION_FROM}}} {{{auctionTimePart}}}
									{{/if}}
								</div>
								<div>
									<strong>{{{JspPublicCodes_JSP_AUCTION_INFORMATION_CURRENCY}}}:</strong>
									{{{currencyCode}}}
								</div>

								{{#if notCatalogue}}
									<strong>{{{JspPublicCodes_JSP_AUCTION_INFORMATION_BUYERS_PREMIUM}}}:</strong>
									<span id="buyersPremium">{{#if buyersPremiumRequired}} {{{buyersPremium}}}&#37; {{else}} 0&#37; {{/if}}{{{JspPublicCodes_JSP_AUCTION_INFORMATION_OR_AS_SPECIFIED}}}</span>
									<br/>
									{{#if salesTaxRequired}}
										<span id="salesTax">
											<strong>{{{JspPublicCodes_JSP_AUCTION_INFORMATION_SALES_TAXES}}}:</strong>
											<br/>
											<span style="margin-left: 30px;">{{{JspPublicCodes_JSP_AUCTION_INFORMATION_HAMMER_TAX_RATE}}}: {{{hammerTaxRate}}}&#37; {{{JspPublicCodes_JSP_AUCTION_INFORMATION_OR_AS_SPECIFIED}}}</span>
											<br/>
											<span style="margin-left: 30px;">{{{JspPublicCodes_JSP_AUCTION_INFORMATION_BP_TAX_RATE}}}: {{{bpTaxRate}}}&#37; {{{JspPublicCodes_JSP_AUCTION_INFORMATION_OR_AS_SPECIFIED}}}</span>
											<br/>
											<span style="margin-left: 30px;">{{{JspPublicCodes_JSP_AUCTION_INFORMATION_CHARGES_TAX_RATE}}}: {{{chargesTaxRate}}}&#37; {{{JspPublicCodes_JSP_AUCTION_INFORMATION_OR_AS_SPECIFIED}}}</span>
											<br/>
										</span>
										{{else}}
										<span id="noSalesTax">
											{{{JspPublicCodes_JSP_AUCTION_INFORMATION_SALES_TAX_NOT_REQUIRED_MESSAGE}}}
										</span>
									{{/if}}
								{{/if}}
								<br/>
								<strong>{{{JspPublicCodes_JSP_AUCTION_INFORMATION_VIEWING_DAYS}}}:</strong>
								<br/>
								<span id="viewingDaysInfo">
									{{#if hasViewingDates}}
									<span style="margin-left: 30px;">{{{viewingStartsDatePart}}} - {{{viewingClosesDatePart}}}</span>
									<br />
									<span style="margin-left: 30px;">{{{JspPublicCodes_JSP_AUCTION_INFORMATION_FROM}}}
									{{{viewingStartsTimePart}}} {{{JspPublicCodes_JSP_AUCTION_INFORMATION_TO}}} {{{viewingClosesTimePart}}} </span>
									<br />
									{{/if}}
									{{#if requireAppointmentForViewing}}
									<span style="margin-left: 30px;">{{{JspPublicCodes_JSP_AUCTION_INFORMATION_BY_APPOINTMENT}}}</span>
									<br />
									{{/if}}
									{{#if viewingComment}}
									<span style="margin-left: 30px;">{{{viewingComment}}}</span>
									{{/if}}
								</span>
								<br/>
								{{#if hasClearanceDatesOrComment}}
								<strong>{{{JspPublicCodes_JSP_AUCTION_INFORMATION_CLEARANCE_DAYS}}}:</strong>
								<br />
								<span id="clearanceDaysInfo">
									{{#if hasClearanceDates}}
									<span style="margin-left: 30px;">{{{clearanceStartsDatePart}}} - {{{clearanceClosesDatePart}}}</span>
									<br/>
									<span style="margin-left: 30px;">{{{JspPublicCodes_JSP_AUCTION_INFORMATION_FROM}}} {{{clearanceStartsTimePart}}} {{{JspPublicCodes_JSP_AUCTION_INFORMATION_TO}}} {{{clearanceClosesTimePart}}}</span>
									<br />
									{{/if}}
									{{#if clearanceComment}}
										<span style="margin-left: 30px;">{{{clearanceComment}}}</span>
									{{/if}}
								</span>
								{{/if}}
						</div>
					</div>
					<div class="col-md-6">
						<div class="well">
							<address>
								{{#if hasAddress}}
								<h5>{{{JspPublicCodes_JSP_AUCTION_INFORMATION_SITE_ADDRESS}}}:</h5>
								<p id="saleInfoAddress">{{{address}}}</p>
								{{/if}}
							</address>
						</div>
					</div>
				</div>
				<div class="row sale-info-slide-content">
					<div class="col-md-12">
						<div class="w2-saleinfo-tab" id="sale-info">
							<div class="panel-group" id="saleInfoAccordion" role="tablist" aria-multiselectable="false">
								{{#if notCatalogue}} {{#if contentPages.hasAuctionCollection}}
									<div class="panel panel-default">
										<div class="panel-heading" role="tab" id="siHeadingOne">
											<h4 class="panel-title">
												<a class="x-toggle collapsed" role="button" data-toggle="saleInfoCol1" data-parent="#saleInfoAccordion" href="#saleInfoCol1" aria-expanded="false"
													aria-controls="saleInfoCol1"><i class="fa fa-truck"></i>&nbsp;{{{JspPublicCodes_JSP_AUCTION_INFORMATION_COLLECTION}}}</a>
											</h4>
										</div>
										<div id="saleInfoCol1" class="panel-collapse collapse" role="tabpanel" aria-labelledby="siHeadingOne">
											<div class="panel-body">{{{contentPages.auctionCollection}}}</div>
										</div>
									</div>
								{{/if}} {{#if contentPages.hasAuctionTerms}}
								<div class="panel panel-default">
									<div class="panel-heading" role="tab" id="siHeadingTwo">
										<h4 class="panel-title">
											<a class="x-toggle collapsed" role="button" data-toggle="saleInfoCol2" data-parent="#saleInfoAccordion" href="#saleInfoCol2" aria-expanded="false"
												aria-controls="saleInfoCol2"><span class="fa fa-files-o"></span>&nbsp;{{{JspPublicCodes_JSP_AUCTION_INFORMATION_TS_AND_CS}}}</a>
										</h4>
									</div>
									<div id="saleInfoCol2" class="panel-collapse collapse" role="tabpanel" aria-labelledby="siHeadingTwo">
										<div class="panel-body">{{{contentPages.auctionTerms}}}</div>
									</div>
								</div>
								{{/if}} {{#if contentPages.hasAuctionPayment}}
								<div class="panel panel-default">
									<div class="panel-heading" role="tab" id="siHeadingThree">
										<h4 class="panel-title">
											<a class="x-toggle collapsed" role="button" data-toggle="saleInfoCol3" data-parent="#saleInfoAccordion" href="#saleInfoCol3" aria-expanded="true"
												aria-controls="saleInfoCol3"><span class="fa fa-credit-card"></span>&nbsp;{{{JspPublicCodes_JSP_AUCTION_INFORMATION_PAYMENT}}}</a>
										</h4>
									</div>
									<div id="saleInfoCol3" class="panel-collapse collapse" role="tabpanel" aria-labelledby="siHeadingThree">
										<div class="panel-body">{{{contentPages.auctionPayment}}}</div>
									</div>
								</div>
								{{/if}} {{#if contentPages.hasAuctionGoods}}
								<div class="panel panel-default">
									<div class="panel-heading" role="tab" id="siHeadingFour">
										<h4 class="panel-title">
											<a class="x-toggle collapsed" role="button" data-toggle="saleInfoCol4" data-parent="#saleInfoAccordion" href="#saleInfoCol4" aria-expanded="false"
												aria-controls="saleInfoCol4"><span class="fa fa-truck"></span>&nbsp;{{{JspPublicCodes_JSP_AUCTION_INFORMATION_REMOVAL_OF_GOODS}}}</a>
										</h4>
									</div>
									<div id="saleInfoCol4" class="panel-collapse collapse" role="tabpanel" aria-labelledby="siHeadingFour">
										<div class="panel-body">{{{contentPages.auctionGoods}}}</div>
									</div>
								</div>
								{{/if}} {{/if}} {{#if contentPages.auctionContacts}}
								<div class="panel panel-default">
									<div class="panel-heading" role="tab" id="siHeadingFive">
										<h4 class="panel-title">
											<a class="x-toggle collapsed" role="button" data-toggle="saleInfoCol5" data-parent="#saleInfoAccordion" href="#saleInfoCol5" aria-expanded="false"
												aria-controls="saleInfoCol5"><span class="fa fa-phone"></span>&nbsp;{{{JspPublicCodes_JSP_AUCTION_INFORMATION_CONTACTS}}}</a>
										</h4>
									</div>
									<div id="saleInfoCol5" class="panel-collapse collapse" role="tabpanel" aria-labelledby="siHeadingFive">
										<div class="panel-body">{{{contentPages.auctionContacts}}}</div>
									</div>
								</div>
								{{/if}}
							</div>

							{{#if documents}}
							<div class="col-md-12">
								<h5>{{{JspPublicCodes_JSP_AUCTION_INFORMATION_ADDITIONAL_DOCS}}}</h5>
								<ul class="documents">
									{{#documents}}
									<li><a href="{{attachmentUrl}}" target="_blank" title="{{label}}">{{label}}</a> &nbsp;<span class="comment">[{{size}}kB]</span></li> {{/documents}}
								</ul>
							</div>
							{{/if}}
						</div>

						{{#if validLatLong}}
							{{#if mapsApiKey}}
								<iframe
									id="mapSaleInfo"
									width="100%"
									height="450"
									frameborder="0"
									style="border:0"
									src="https://www.google.com/maps/embed/v1/place?key={{mapsApiKey}}&q={{locationLatitude}},{{locationLongitude}}"
									allowfullscreen
								/>
							{{/if}}
						{{/if}}


						<div id="saleInfoSlide"></div>

					</div>
				</div>
			</div>
			<div class="modal-footer">
				<button type="submit" class="x-cancel btn btn-primary">{{{JSTextCodes_CLOSE}}}</button>
			</div>
		</div>
	</div>
{{/if}}
