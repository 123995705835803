<div id="address-display">
<div id="address-title" class="form-group">
	<div class="col-sm-4 text-right">
		<strong>&nbsp;</strong>
	</div>
	<div class="col-sm-6">
		<h4>{{{viewTitle}}}</h4>
	</div>
</div>
<div class="form-group">
	<div class="col-sm-4 text-right">
		<strong>Line 1</strong>
	</div>
	<div class="col-sm-6">{{{address.addressLine1}}}</div>
	{{#if allowEdit}}
		<div class="col-sm-2 pull-right">
			<a href="#" class="x-edit-address">edit</a>
		</div>
	{{/if}}
</div>
<div class="form-group">
	<div class="col-sm-4 text-right">
		<strong>Line 2</strong>
	</div>
	<div class="col-sm-6">{{{address.addressLine2}}}</div>
</div>
<div class="form-group">
	<div class="col-sm-4 text-right">
		<strong>Line 3</strong>
	</div>
	<div class="col-sm-6">{{{address.addressLine3}}}</div>
</div>
<div class="form-group">
	<div class="col-sm-4 text-right">
		<strong>Line 4</strong>
	</div>
	<div class="col-sm-6">{{{address.addressLine4}}}</div>
</div>
<div class="form-group">
	<div class="col-sm-4 text-right">
		<strong>City</strong>
	</div>
	<div class="col-sm-6">{{{address.city}}}</div>
</div>
<div class="form-group">
	<div class="col-sm-4 text-right">
		<strong>County</strong>
	</div>
	<div class="col-sm-6">{{{address.county}}}</div>
</div>
<div class="form-group">
	<div class="col-sm-4 text-right">
		<strong>Post Code</strong>
	</div>
	<div class="col-sm-6">{{{address.postcode}}}</div>
</div>
<div class="form-group">
	<div class="col-sm-4 text-right">
		<strong>Country</strong>
	</div>
	<div class="col-sm-6">{{{address.countryName}}}</div>
</div>
</div>
