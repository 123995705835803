
<div class="modal-header">
	<button type="button" class="close" data-dismiss="modal" aria-label="Close">
		<span aria-hidden="true">&times;</span><span class="sr-only">{{{JSTextCodes_CLOSE}}}</span>
	</button>
	<h4 class="modal-title" id="myModalLabel">{{{title}}}</h4>
</div>
<div class="modal-body">
	<h3>{{{JspPublicCodes_JSP_CONTACT_SELLER_MESSAGE_SENT}}}</h3>
	<p>{{{message}}}</p>
</div>
<div class="modal-footer">
	<button type="submit" class="x-cancel btn btn-primary">{{{JSTextCodes_CLOSE}}}</button>
</div>
