{{#if filterHeaderName}}
<div class="panel panel-default category-filter-panel">
	<div class="panel-body">
		<span class="category-filter-title">{{{filterHeaderName}}}</span>{{/if}}
		<ul class="filter-list continent-filters m-b-xs">
			{{#filterItems}}
				<li>
					<a id="{{../level}}-{{item.id}}" class="x-category filter-item" href="#" data-itemid="{{item.id}}" data-treeid="{{../item.id}}" data-level="{{../level}}">
		     			<span>{{{item.name}}}
		     				<span id="count-{{../level}}-{{item.id}}" class="count">({{count}})</span>
		     			</span>
		         	</a>
				</li>
			{{/filterItems}}
		</ul>
	</div>
</div>
