<div class="row">
	{{#if placing}}
		<div class="col-xs-12 margin-top">
			<button id="cancelButton{{id}}" class="x-cancel btn btn-block btn-danger" type="button">{{{JspPublicCodes_JSP_BIDDING_CANCEL}}}</button>
		</div>
	{{else if offerModel.offerEnabled}}
		<div class="col-xs-12 margin-top">
			<button id="{{offerModel.offerActionId}}Button_{{id}}" class="x-{{offerModel.offerAction}} btn btn-block btn-primary" type="button" {{#if shouldBiddingBeDisabled}}disabled="disabled"{{/if}}>{{{offerModel.offerButtonLabel}}}</button>
		</div>
	{{/if}}
	{{#if shouldShowActions}}
		{{#if showBidButton}}
			<div class="col-xs-12 margin-top">
				<button id="{{actionId}}Button{{id}}" class="x-{{action}} btn btn-block btn-primary {{bidButtonDisabledProperty}}" data-amount="{{amountNextBid}}" type="button" {{#if shouldBiddingBeDisabled}}disabled="disabled"{{/if}}>{{{buttonLabel}}}</button>
			</div>
		{{/if}}
		{{#if shouldBiddingBeDisabled}}
			{{#if buttonHelpText}}
				<div class="col-xs-12">
					<p class='text-muted'><small>{{{buttonHelpText}}}</small></p>
				</div>
			{{/if}}
		{{/if}}
	{{/if}}
	{{#if showSimilarButton}}
		<div class="col-xs-12 margin-top">
			<button id="viewSimilarItems{{id}}" class="x-{{action}} btn btn-block btn-primary" type="button">{{{buttonLabel}}}</button>
		</div>
	{{/if}}
</div>
{{#if offerModel.offerMessage}}
	<small>{{{offerModel.offerMessage}}}</small>
{{/if}}

{{#if shouldShowActions}}
	{{#if availableForBidding}}
		{{#if highestAutoBid}}
			<div class="margin-top">
				<small>{{{highestAutoBidFormatted}}}</small>
			</div>
		{{/if}}
		{{#if supportsBidding}}
		{{#if registrant.approved}}
			<div class="input-group margin-top">
				<input id="autobidInput_{{id}}" type="text" class="form-control x-auto-bid-input" placeholder="{{{placeHolderMessage}}}" value="{{autoBidValue}}" maxlength="16" {{#if shouldBiddingBeDisabled}}disabled="disabled"{{/if}}>
				{{#if error}}
					<span class="input-group-btn">
						<button id="autobidCancel_{{id}}" type="button" class="x-auto-bid-cancel btn btn-danger" type="button" {{#if shouldBiddingBeDisabled}}disabled="disabled"{{/if}}>{{{JspPublicCodes_JSP_BIDDING_CANCEL}}}</button>
					</span>
				{{/if}}
				<span class="input-group-btn">
					<button id="autobid_{{id}}" type="button" class="x-auto-bid btn btn-primary" type="button" data-reconfirmed="{{reconfirmed}}" {{#if shouldBiddingBeDisabled}}disabled="disabled"{{/if}}>{{{JspPublicCodes_JSP_BIDDING_CONFIRM}}}</button>
				</span>
			</div>
		{{/if}}
		{{/if}}
		{{#if error}}
			<div class="alert alert-danger margin-top margin-bottom--none">
				{{{errorMessage}}}
			</div>
		{{/if}}
	{{/if}}
{{/if}}

{{#if isLoggedIn}}
	{{#if showWatchCheckBox}}
		<div class="btn-group btn-group-justified margin-top" role="group" aria-label="lot watch actions">
			{{#if watching}}
				<div class="btn btn-default btn-sm active clickable x-watching" id="watching_{{id}}">
					<i class="fa fa-binoculars"></i> {{{JspPublicCodes_JSP_BIDDING_WATCHING_ITEM}}}
				</div>
			{{else}}
				<div class="btn btn-default btn-sm clickable x-watching" id="watching_{{id}}">
					<i class="fa fa-binoculars"></i> {{{JspPublicCodes_JSP_BIDDING_WATCH_ITEM}}}
				</div>
			{{/if}}
			{{#if showGotoWatchListLink}}
				<a id="watchingLink_{{id}}" href="{{contextPath}}/myBids/3" class="watchlist-link btn btn-default btn-sm">
					<i class="fa fa-th-list"></i> {{{JspPublicCodes_JSP_BIDDING_GOTO_WATCH_LIST}}}
				</a>
			{{/if}}
		</div>
	{{/if}}
	{{#if contactSellerEnabled}}
		<div id="contactSeller_{{id}}" class="x-contact-seller btn btn-default btn-block margin-top">
			<i class="fa fa-envelope"></i> {{{JspPublicCodes_JSP_BIDDING_CONTACT_SELLER}}}
		</div>
	{{/if}}
	</div>
{{/if}}
