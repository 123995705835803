
<div class="modal-dialog" role="document">
	<div class="modal-content">
		<div class="modal-header">
			<button type="button" class="close" data-dismiss="modal" aria-label="Close">
				<span aria-hidden="true">&times;</span><span class="sr-only">{{{JSTextCodes_CLOSE}}}</span>
			</button>
			<h4 class="modal-title" id="myModalLabel">{{{JspPublicCodes_PURCHASE_DIALOG_TITLE}}}</h4>
		</div>
		<div class="modal-body">
			<div class="row">
				<div class="col-md-12">
					<h3>{{{JspPublicCodes_PURCHASE_DIALOG_TENDER_TITLE}}}</h3>
				</div>
			</div>

			<h4>{{{numberOrRef}}}: {{{title}}}</h4>

			{{#if isTenderPct}}
				<h4>{{{JspPublicCodes_JSP_BIDDING_NUMBER_OF_UNITS_FOR_TENDER}}}: {{numberOfUnits}}</h4>
			{{else}}
				<h4>{{{JspPublicCodes_JSP_BIDDING_NUMBER_OF_UNITS_FOR_TENDER}}} ({{{unitOfMeasurement}}}): {{numberOfUnits}}</h4>
			{{/if}}


			{{#if allowedMoreOffers}}
				{{#if hasMessage}}
					<div id="tenderResultMessage" class="{{resultClass}} text-left">
						<span class="col-sm-4">&nbsp;</span>
						<span class="col-sm-6 panel panel-default">{{{message}}}</span>
					</div>
				{{/if}}

				<div class="form-group {{error1Class}}">
					<label class="col-sm-4 control-label" for="offerAmount">{{{label1}}}</label>

					{{#if isTenderPct}}
						<div class="col-sm-3 input-group">
							<input type="text" class="x-decimal-add-commas form-control" id="offerAmount" placeholder="Amount" value="" maxlength="{{maxAmountLength}}"/>
							<div class="input-group-addon">%</div>
						</div>
					{{else}}
						<div class="col-sm-6 input-group">
							<div class="input-group-addon">{{{currencySymbol}}}</div>
							<input type="text" class="x-decimal-add-commas form-control" id="offerAmount" placeholder="Amount" value="" maxlength="{{maxAmountLength}}"/>
						</div>
					{{/if}}
				</div>

				<div class="form-group has-warning">
					<label class="col-sm-4 control-label" for="">&nbsp;</label>
					<div class="col-sm-6 input-group">

					{{#if isTenderPct}}
						{{{unitOfMeasurement}}}
					{{else}}
						{{{JspPublicCodes_JSP_BIDDING_UNIT_OF_MEASUREMENT_PER}}} {{{unitOfMeasurement}}}
					{{/if}}

					</div>
				</div>

				{{#if hasBp}}
					<div class="form-group has-warning">
						<label class="col-sm-4 control-label" for="">&nbsp;</label>
						<div class="col-sm-6 input-group">
							<p class="help-block">plus {{{JspPublicCodes_PURCHASE_DIALOG_BUYERS_PREMIUM_AT}}} %{{{buyersPremium}}}</p>
						</div>
					</div>
				{{/if}}
			{{else}}
				{{#if hasMessage}}
					<div id="tenderResultMessage" class="{{resultClass}} row text-left">
						<span class="col-sm-3">&nbsp;</span>
						<span class="col-sm-6 panel panel-default">{{{message}}}</span>
						<span class="col-sm-3">&nbsp;</span>
						</p>
					</div>
				{{/if}}
			{{/if}}

			<div class="modal-footer">
				<div class="row">
					<div class="col-md-12">
						{{#if allowedMoreOffers}}
							<button id="tender-dialog-proceed" type="submit" class="{{{action}}} btn btn-primary">{{{JSTextCodes_PROCEED}}}</button>
						{{/if}}
						<button type="submit" class="x-cancel btn btn-primary">{{{JSTextCodes_CLOSE}}}</button>
					</div>
				</div>
				<div class="row text-left" style="margin: 20px 0 0 0;">
					<div class="col-md-12">
						<div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
							<div class="panel panel-default">
								<div class="panel-heading" role="tab" id="headingOne">
									<h4 class="panel-title">
										<a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="false"
											aria-controls="collapseOne">{{{helpTitle}}}</a>
									</h4>
								</div>
								<div id="collapseOne" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
									<div class="panel-body">{{{helpContent}}}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
