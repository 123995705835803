
<div class="modal-dialog">
	<div class="modal-content">
		<div class="modal-header">
			<button type="button" class="close" data-dismiss="modal" aria-label="Close">
				<span aria-hidden="true">&times;</span><span class="sr-only">{{{JSTextCodes_CLOSE}}}</span>
			</button>
			<h4 class="modal-title" id="myModalLabel">{{{JspPublicCodes_PURCHASE_DIALOG_TRANSFER}}}</h4>
		</div>
		<div class="modal-body">
			<h3>{{{userStatusMessage}}}</h3>
		</div>
		<div class="modal-footer">
			<button type="submit" class="x-cancel btn btn-primary">{{{JSTextCodes_CLOSE}}}</button>
		</div>
	</div>
</div>
