<div class="panel-group" id="continentsAccordian" role="tablist" aria-multiselectable="true">
	<div class="panel panel-default">
		<div class="panel-heading" role="tab" id="continentFilters">
			<h4 class="panel-title">
				<a role="button" data-toggle="collapse" data-parent="#continentsAccordian" href="#continentsPanel" aria-expanded="{{continentExpanded}}" aria-controls="continentsPanel">
					{{{filterHeaderName}}}<i class="fa fa-bars pull-right"></i>
				</a>
			</h4>
		</div>
		{{#if continentExpanded}}
		<div id="continentsPanel" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="continentFilters">
		{{else}}
		<div id="continentsPanel" class="panel-collapse collapse" role="tabpanel" aria-labelledby="continentFilters">
		{{/if}}
			<div class="panel-body">
				<ul class="filter-list continent-filters list-unstyled">
					{{#filterItems}}
						<li class="filter-title clearfix">
							<label for="continent-checkbox{{item.id}}" {{selectedClass}}>{{{item.name}}}</label>
							<input class="x-continent pull-right" id="continent-checkbox{{item.id}}" type="checkbox" data-itemid="{{selectedObjects}}" name="continent"/>
							{{#if childItems.length}}
								<ul class="filter-list continent-filters margin-bottom">
									{{#childItems}}
										<li class="g2-cont-child-item">
											<a class="x-country filter-item" data-itemid="{{item.id}}" href="#">
												<span id="{{../../childQueryParameterName}}-{{item.id}}">{{{item.name}}} <span id="count-{{../../childQueryParameterName}}-{{item.id}}" class="count">({{count}})</span></span>
											</a>
										</li>
									{{/childItems}}
								</ul>
							{{/if}}
						</li>
					{{/filterItems}}
				</ul>
			</div>
		</div>
	</div>
</div>
