
<div class="form-group">
	<label class="control-label">{{{JspPublicCodes_JSP_MY_SALES_DATE_FILTER_FROM}}}</label> 
	<input id="startDate" type="text" class="x-datepicker form-control" value="" data-date-format="yyyy-mm-dd hh:ii" maxlength="20">
</div>
<div class="form-group">
	<label class="control-label">{{{JspPublicCodes_JSP_MY_SALES_DATE_FILTER_TO}}}</label> 
	<input id="endDate" type="text" class="x-datepicker form-control" value="" data-date-format="yyyy-mm-dd hh:ii" maxlength="20">
</div>

<button id="dateButton" class="x-search-dates filter-button button button-primary" type="submit">
	<span>{{{JspPublicCodes_JSP_MY_SALES_DATE_FILTER_APPLY}}}</span>
</button>
