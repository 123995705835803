<div class="modal-dialog">
	<div class="modal-content">
		<div class="modal-header">
			<button type="button" class="close" data-dismiss="modal" aria-label="Close">
				<span aria-hidden="true">&times;</span><span class="sr-only">{{{JSTextCodes_CLOSE}}}</span>
			</button>
			<h4 class="modal-title" id="myModalLabel">Buy This Item Now</h4>
		</div>
		<div class="modal-body">
		{{#if closed}}
			<p>{{{userStatusMessage}}}</p>
		{{else}}
			<h3>{{{numberOrRef}}}: {{{title}}}</h3>
			<p>{{{JspPublicCodes_PURCHASE_DIALOG_PRICE}}} {{{currencySymbol}}}{{{minimumOfferOrBuyNowPrice}}} +
				{{{JspPublicCodes_PURCHASE_DIALOG_BUYERS_PREMIUM_AT}}} %{{{buyersPremium}}}</p>
			<div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
				<div class="panel panel-default">
					<div class="panel-heading" role="tab" id="headingOne">
						<h4 class="panel-title">
							<a class="collapsed" role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne" aria-expanded="false" aria-controls="collapseOne">{{{PageCodes_PAGE_BUY_NOW_HELP_TITLE}}}</a>
						</h4>
					</div>
					<div id="collapseOne" class="panel-collapse collapse" role="tabpanel" aria-labelledby="headingOne">
						<div class="panel-body">{{{PageCodes_PAGE_BUY_NOW_HELP}}}</div>
					</div>
				</div>
			</div>
		{{/if}}
		</div>
		<div class="modal-footer">
		{{#if closed}}
			<button type="submit" class="x-cancel btn btn-primary">{{{JSTextCodes_CLOSE}}}</button>
		{{else}}
			<button type="submit" class="x-continue btn btn-primary">{{{JSTextCodes_PROCEED}}}</button>
			<button type="submit" class="x-cancel btn btn-primary">{{{JSTextCodes_CLOSE}}}</button>
		{{/if}}
		</div>
	</div>
</div>
