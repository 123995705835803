{{#if sashModel.showSash}}<div id="sashContainer{{id}}" class="{{{sashModel.sashName}}} iepng"></div>{{/if}}
<div class="t2-timed-title text-center">
<!-- timed-gal-bids -->
	<h5 id="lotTitle{{id}}">
		{{#if showItemDetailsLink}}
			<a id="itemDetails{{id}}" href="{{contextPath}}/itemDetails/{{auctionId}}/{{id}}" class="evh_LoadItemDetails" data-id="{{id}}">{{{title}}}</a>
		{{else}}
			{{{title}}}
		{{/if}}
	</h5>
</div>
<div class="t2-timed-summary text-center">
<!-- timed-gal-bids -->
	<p id="lotSummary{{id}}">{{{summary}}}</p>
</div>
