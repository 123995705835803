<div class="filter-list continent-filters panel-group" id="filters-accordion" role="tablist" aria-multiselectable="true">
    {{#trees}}
        <div class="panel panel-default">
            <div class="panel-heading" role="tab" id="headingOne">
                <h4 class="panel-title">
                    <a role="button" data-toggle="collapse" data-parent="#accordion" href="#collapseOne-{{@index}}" aria-expanded="true" aria-controls="collapseOne">
                        {{{item.name}}}<i class="fa fa-bars pull-right"></i>
                    </a>
                </h4>
            </div>
            <div id="collapseOne-{{@index}}" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="headingOne">
                <ul class="list-group filter-list">
                    {{#childItems}}
                        {{#if display}}
                            {{#if count}}
                                <li class="list-group-item">
                                    <a class="x-category filter-item g2-tree-title" href="#" data-itemid="{{item.id}}" data-treeid="{{../item.id}}" data-level="category">
                                        <span id="category-{{item.id}}"><strong>{{{item.name}}} <span id="count-category-{{item.id}}" class="count">({{count}})</span></strong></span>
                                    </a>
                                    {{#unless ../../overflow}}
                                        <ul class="filter-list">
                                            {{#childItems}}
                                                {{#if count}}
                                                    <li class="g2-tree-item">
                                                        <a class="x-category filter-item" href="#" data-itemid="{{item.id}}" data-treeid="{{../../item.id}}" data-level="subCategory">
                                                            <span id="subCategory-{{item.id}}" >{{{item.name}}} <span id="count-subCategory-{{item.id}}" class="count">({{count}})</span></span>
                                                        </a>
                                                    </li>
                                                {{/if}}
                                            {{/childItems}}
                                        </ul>
                                    {{/unless}}
                                </li>
                            {{/if}}
                        {{/if}}
                    {{/childItems}}
                    {{#if overflow}}
                        <li class="list-group-item">
                            <a id="se-moreorless-{{item.id}}" class="x-{{../moreorless}} filter-item" href="#" data-treeid="{{item.id}}">
                                <span id="displayMoreFilterItems{{item.id}}" class="p-l-sm p-b-xs">{{{../overflowLabel}}}</span>
                            </a>
                        </li>
                    {{/if}}
                </ul>
            </div>
        </div>
    {{/trees}}
</div>
