
<div class="modal-dialog">
	<div class="modal-content">
		<div class="modal-header">
			<button type="button" class="close" data-dismiss="modal" aria-label="Close">
				<span aria-hidden="true">&times;</span><span class="sr-only">{{{JSTextCodes_CLOSE}}}</span>
			</button>
			<h4 class="modal-title" id="myModalLabel">{{{title}}}</h4>
		</div>
		<div class="modal-body">
			<h3>{{{JspPublicCodes_JSP_CONTACT_SELLER_OF_ITEM}}}</h3>
			<form>
				<div class="form-group {{messageClass}}">
					<label>{{{JspPublicCodes_JSP_CONTACT_SELLER_YOUR_MESSAGE}}}</label>
					<textarea id="contactSellerMessage" class="form-control" rows="3">{{{message}}}</textarea>
				</div>
				<div class="form-group">
					<p class="help-block">{{{JspPublicCodes_JSP_CONTACT_SELLER_ABOUT}}}</p>
				</div>
			</form>
		</div>
		<div class="modal-footer">
			<button type="submit" class="x-submit btn btn-primary">{{{JSTextCodes_SUBMIT}}}</button>
			<button type="submit" class="x-cancel btn btn-primary">{{{JSTextCodes_CLOSE}}}</button>
		</div>
	</div>
</div>
