<div class="col-xs-12 margin-top margin-bottom">
  <div class="row">
    {{#if gotoItemFeature}}
      <div class="pull-right col-xs-12 col-sm-6 col-md-4 text-right">
        <form class="x-ta-filters">
          <div class="form-group">
            <div class="input-group">
              <input id="goto--input" type="text" class="x-typeahead-item form-control" aria-label="..." placeholder="{{{JspPublicCodes_JSP_TIMED_GOTO_ITEM_PH}}}">
              <div class="input-group-btn dropdown">
                <button id="goto--type-select" type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  {{{findByLabel}}} <span class="caret"></span>
                </button>
                <ul class="dropdown-menu">
                  <li id="goto--type-select--lot-number"><a href="#" class="x-ta-find-by" data-findby="lotNumber" data-findbyph="">{{{JspPublicCodes_JSP_TIMED_GOTO_BY_LOTNUMBER}}}</a></li>
                  <li id="goto--type-select--title"><a href="#" class="x-ta-find-by" data-findby="title" data-findbyph="">{{{JspPublicCodes_JSP_TIMED_GOTO_BY_TITLE}}}</a></li>
                </ul>
                <button id="goto--submit" type="button" class="x-ta-go btn btn-primary" disabled="disabled" aria-haspopup="true" aria-expanded="false"><i class="fa fa-paper-plane-o" aria-hidden="true"></i></button>
              </div>
            </div>
          </div>
        </form>
      </div>
    {{/if}}
    {{#unless isShowOpenDisabled}}
      <div class="pull-right col-xs-12 col-sm-6 col-md-4 col-lg-3">
        <div class="input-group x-ta-show-open">
          <span class="input-group-addon">
            <input id="show-open--checkbox" type="checkbox" aria-label="..." {{showOpenOnlyChecked}}>
          </span><label id="show-open--label" class="form-control">{{{JspPublicCodes_JSP_TIMED_SHOW_OPEN_LABEL}}}</label>
        </div>
      </div>
    {{/unless}}
  </div>
</div>
<p id="timedAuctionMessage" class="alert alert-warning hidden col-xs-12 margin-top margin-bottom"></p>
{{#if showFilters}}
  <div class="col-xs-12 col-md-3 margin-bottom">
{{else}}
  <div class="col-xs-12">
    <div class="pull-left">
{{/if}}
  <div class="btn-group tools-toggle">
    {{#if showSaleInfoInPage}}
      {{#if isAuctionLand}}
        <button id="switch--sale-info" type="button" class="btn btn-default {{saleInfoActive}} x-landing-sale-info-toggle"><a href="">{{{saleInfoLabel}}} <i class="{{lotLayout}} fa fa-chevron-{{{saleInfoChevronDirection}}}"></i></a></button>
        <button id="switch--filters" type="button" class="btn btn-default {{filtersActive}} x-landing-filter-toggle"><a href="">{{{landingFilterLabel}}} <i class="{{lotLayout}} fa fa-chevron-{{{filtersChevronDirection}}}"></i></a></button>
      {{/if}}
    {{else}}
      {{#if landingFilterToggles}}
        <button id="switch--filters" type="button" class="btn btn-default {{filterBtnClazz}} x-landing-filter-toggle"><a href=""><i class="fa fa-filter"></i> {{{landingFilterLabel}}}</a></button>
      {{/if}}
      {{#if saleInfoFilterToggles}}
        <button id="switch--sale-info" type="button" class="btn btn-default {{saleinfoBtnClazz}} x-saleinfo-modal"><a href=""><i class="fa fa-info"></i> {{{saleInfoLabel}}}</a></button>
      {{/if}}
    {{/if}}
    {{#if mybidsFilterToggles}}
      {{#mybidsFilterSet}}
        <button id="switch--my-bids-filters--{{id}}" type="button" class="btn btn-default {{clazz}} x-filter" data-filter="{{id}}"><a id="biddingFilter{{id}}" href="">{{{label}}}</a></button>
      {{/mybidsFilterSet}}
    {{/if}}
  </div>
</div>
{{#if showFilters}}
  <div class="col-xs-12 col-md-9">
{{/if}}
  {{#if showLayoutToggle}}
    <ul id="secondary-nav" class="nav nav-tabs hidden-xs hidden-sm margin-bottom">
      <li id="toggle-layout--grid" class="{{gridBtnClazz}} pull-right x-layout" data-layout="grid"><a href="" role="presentation"><i class="fa fa-th"></i> {{{gridView}}}</a></li>
      <li id="toggle-layout--list" class="{{listBtnClazz}} pull-right x-layout" data-layout="list"><a href="" role="presentation"><i class="fa fa-list-ul"></i> {{{listView}}}</a></li>
    </ul>
  {{/if}}
{{#unless showFilters}}
  </div>
{{/unless}}
</div>
