<div class="container my-bids-container">
	<h1>{{{JspPublicCodes_JSP_HEADER_MY_BIDS}}}</h1>
	<div class="row">
		<div class="col-xs-12 col-md-3">
			<div id="mbFilters" class="margin-top">
				<div class="row">
					<div id="currentFilterRegion" class="col-xs-12"></div>
				</div>
				
				<div class="row">
					<div id="typesFilterRegion" class="col-xs-12"></div>
				</div>
				
				<div class="row">
					<div id="summaryRegion" class="col-xs-12"></div>
				</div>
				
				<div class="row">
					<div id="datesFilterRegion" class="col-xs-12"></div>
				</div>
				
			</div>

			{{#if showItemAlerts}}
				<div id="maFilters" class="margin-top">
					<h4>{{{JspPublicCodes_JSP_HEADER_MY_ALERTS}}}</h4>
					<div class="row">
						<div id="currentAlertsFilterRegion" class="col-xs-12"></div>
					</div>
					<div class="row">
						<div id="alertsFilterRegion" class="col-xs-12"></div>
					</div>
				</div>
			{{/if}}
		</div>
		
		<div class="col-md-9 col-xs-12">
			<div class="row">
				<div id="pagingTopRegion" class="col-xs-12"></div>
			</div>
			<div id="layoutControlRegion" class="row"></div>
			<div class="row">
				<div id="biddingRegion" class="col-xs-12"></div>
			</div>
			<div class="row">
				<div id="pagingBottomRegion" class="col-xs-12"></div>
			</div>
		</div>
	</div>
</div>
