<div class="col-md-12 display-3">
  <span class="catalogue-message">
    <span id="catalogueItem{{id}}">
      {{{JspPublicCodes_JSP_CATALOGUE_ITEM}}}
    </span>
  </span>
</div>

<div class="col-md-12 text-center">
  {{#if hasExternalWebpageUrl}}
    {{#unless archived}}
      <a id="gotoExternalWebsite{{id}}" href="{{{fullExternalWebpageUrl}}}" target="_blank">
        <button id="viewSimilarItems{{id}}" id="{{actionId}}Button{{id}}" class="x-{{action}} btn btn-primary"
          type="button">
          {{{JspPublicCodes_JSP_CATALOGUE_GOTO_EXTERNAL_WEBSITE}}}
          <i class="fa fa-external-link" aria-hidden="true"></i>
        </button>
      </a>
    {{/unless}}
  {{/if}}
</div>