<div class="modal-dialog">
	<div class="modal-content">
		<div class="modal-header">
			<button type="button" class="close" data-dismiss="modal" aria-label="Close">
				<span aria-hidden="true">&times;</span><span class="sr-only">{{{JSTextCodes_CLOSE}}}</span>
			</button>
			<h4 class="modal-title" id="myModalLabel">{{{JspPublicCodes_PURCHASE_DIALOG_FINAL_CONFIRMATION}}}</h4>
		</div>
		<div class="modal-body">
			<div id="contactSellerDialog">
				<h3>{{{numberOrRef}}}: {{{title}}}</h3>
				{{{JspPublicCodes_PURCHASE_DIALOG_PLEASE_PROVIDE_FINAL_CONFIRMATION}}}
				<p>{{{JspPublicCodes_PURCHASE_DIALOG_FINAL_CONFIRMATION_OF_BUY_NOW}}} {{{currencySymbol}}}{{{minimumOfferOrBuyNowPrice}}} +
					{{{JspPublicCodes_PURCHASE_DIALOG_BUYERS_PREMIUM_AT}}} %{{{buyersPremium}}}</p>
			</div>
		</div>
		<div class="modal-footer">
			<button type="submit" class="x-submit btn btn-primary">{{{JSTextCodes_SUBMIT}}}</button>
			<button type="submit" class="x-cancel btn btn-primary">{{{JSTextCodes_CANCEL}}}</button>
		</div>
	</div>
</div>
