{{#if displayFilter}}
  <div class="panel-group" id="auctionTypeAccordion" role="tablist" aria-multiselectable="true">
    <div class="panel panel-default">
      <div class="panel-heading" role="tab" id="auctionTypeFilters">
        <h4 class="panel-title">
          <a role="button" data-toggle="collapse" data-parent="#auctionTypeAccordion" href="#auctionTypePanel" aria-expanded="true" aria-controls="auctionTypePanel">
            {{{filterHeaderName}}}<i class="fa fa-bars pull-right"></i>
          </a>
        </h4>
      </div>
      <div id="auctionTypePanel" class="panel-collapse collapse in" role="tabpanel" aria-labelledby="auctionTypeFilters">
        <div class="panel-body">
          <ul id="auction-type-filters" class="filter-list">
            {{#filterItems}}
            <li class="g2-auctiontype-item-bg">
              <a class="x-type filter-item" href="#" data-itemid="{{item.id}}">
                <span id="{{../queryParameterName}}-{{item.id}}">{{{item.name}}}
                  <span id="count-{{../queryParameterName}}-{{item.id}}" class="count">({{count}})</span>
                </span>
              </a>
            </li>
            {{/filterItems}}
          </ul>
        </div>
      </div>
    </div>
  </div>
{{/if}}
